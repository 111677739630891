<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 mb-3 text-primary">{{ formData.id !== undefined ? "Edit" : "Create"}}
                organisation</h5>
            <form autocomplete="off">
                <h6 class="mb-3">Basic info</h6>
                <div class="px-2">
                    <b-form-group
                            label-size="sm"
                            label="Name:">
                        <b-form-input size="sm" v-model="formData.name" placeholder="Organisation name"></b-form-input>
                        <error-display v-model="errors" ident="name"></error-display>
                    </b-form-group>
                    <div class="row ">
                        <div class="col-5">
                            <b-form-group
                                    label-size="sm"
                                    label="Address line 1:">
                                <b-form-input size="sm" v-model="formData.address_line_1"></b-form-input>
                                <error-display v-model="errors" ident="address_line_1"></error-display>
                            </b-form-group>
                        </div>
                        <div class="col-5">
                            <b-form-group
                                    label-size="sm"
                                    label="Address line 2:">
                                <b-form-input size="sm" v-model="formData.address_line_2"></b-form-input>
                                <error-display v-model="errors" ident="address_line_2"></error-display>
                            </b-form-group>
                        </div>
                        <div class="col-2">
                            <b-form-group
                                    label-size="sm"
                                    label="Postcode:">
                                <b-form-input size="sm" v-model="formData.post_code"></b-form-input>
                                <error-display v-model="errors" ident="post_code"></error-display>
                            </b-form-group>
                        </div>
                    </div>
                    <b-form-group
                            label-size="sm"
                            label="Key contact email address:">
                        <b-form-input size="sm" v-model="formData.notification_email"></b-form-input>
                        <error-display v-model="errors" ident="notification_email"></error-display>
                    </b-form-group>

                </div>

                <hr aria-orientation="horizontal" class="dropdown-divider mt-3 mb-3">
                <h6 class="mb-3">App configuration</h6>
                <div class="px-2">

                    <div class="row">
                        <div class="col-6">
                            <b-form-group
                                    label-size="sm"
                                    label="Student message:">
                                <b-form-textarea size="sm" v-model="formData.student_message"
                                                 placeholder="Shown at the end of the process to users processing a student's application"></b-form-textarea>
                                <error-display ident="student_message" v-model="errors"></error-display>
                            </b-form-group>
                        </div>
                        <div class="col-6">
                            <b-form-group
                                    label-size="sm"
                                    label="Student question:">
                                <b-form-textarea size="sm" v-model="formData.student_question"
                                                 placeholder="Asked to all student applicants within this organisation"></b-form-textarea>
                                <error-display ident="student_question" v-model="errors"></error-display>
                            </b-form-group>
                        </div>

                        <div class="col-6">
                            <b-form-group
                                    label-size="sm"
                                    label="Ask student question when:">
                                <b-select size="sm" v-model="formData.ask_student_question"
                                          :options="studentOptions"
                                          placeholder=""></b-select>
                                <error-display ident="ask_student_question" v-model="errors"></error-display>
                            </b-form-group>
                        </div>

                        <div class="col-6">
                            <b-form-group
                                    label-size="sm"
                                    label="Automated doc checking:">
                                <b-select size="sm" v-model="formData.doc_check_mode"
                                          :options="docCheckOptions"
                                          placeholder=""></b-select>
                                <error-display ident="doc_check_mode" v-model="errors"></error-display>
                            </b-form-group>
                        </div>


                    </div>

                    <b-form-group>
                        <b-form-checkbox
                                size="sm"
                                v-model="formData.is_photo_required"
                                :value="1"
                                unchecked-value="0"
                        >Force users to provide a candidate photo?
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox
                                size="sm"
                                v-model="formData.force_notifications"
                                :value="1"
                                unchecked-value="0"
                        >Force email notifications to be sent
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox
                                size="sm"
                                v-model="formData.force_flagging"
                                :value="1"
                                unchecked-value="0"
                        >Force all applications to undergo supervisor approval
                        </b-form-checkbox>
                    </b-form-group>

                </div>
                <hr aria-orientation="horizontal" class="dropdown-divider mt-3 mb-3">
                <h6 class="mb-3">Login security</h6>
                <div class="px-2">
                    <b-form-group>
                        <b-form-checkbox
                                size="sm"
                                v-model="formData.ip_restriction_enabled"
                                :value="1"
                                unchecked-value="0"
                        >Allow IP whitelist feature?
                        </b-form-checkbox>
                    </b-form-group>

                    <div v-if="formData.ip_restriction_enabled === 1">
                        <b-form-group>
                            <b-form-checkbox
                                    size="sm"
                                    v-model="formData.is_ip_restricted"
                                    :value="1"
                                    unchecked-value="0"
                            >Restrict portal access to IP whitelist?
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                                v-if="formData.is_ip_restricted === 1"
                                label-size="sm"
                                label="Enter white list IP addresses">
                            <b-form-tags size="sm"
                                         v-model="formData.ips"
                                         :tag-validator="ipValidator"></b-form-tags>
                            <error-display ident="ips" v-model="errors"></error-display>
                        </b-form-group>
                    </div>

                </div>

                <hr aria-orientation="horizontal" class="dropdown-divider mt-3 mb-3">
                <h6 class="mb-3">Additional</h6>
                <div class="px-2">
                    <b-form-group
                            label-size="sm"
                            label="License expiry date:">
                        <masked-input class="form-control"
                                      v-model="formData.expires_at"
                                      mask="11/11/1111"
                                      placeholder="dd/mm/yyyy"
                        />
                        <error-display v-model="errors" ident="expires_at"></error-display>
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox
                                size="sm"
                                v-model="formData.auto_approve_device"
                                :value="1"
                                unchecked-value="0"
                        >Auto approve all devices for this organisation
                        </b-form-checkbox>
                    </b-form-group>

                    <b-form-group>
                        <b-form-checkbox
                                size="sm"
                                v-model="formData.pricing_override"
                                :value="1"
                                unchecked-value="0"
                        >Override default pricing for this organisation
                        </b-form-checkbox>
                    </b-form-group>
                    <div class="px-3" v-if="formData.pricing_override === 1">
                        <div class="row border border-primary rounded py-2">
                            <div class="col-6">
                                <b-form-group
                                        label-size="sm"
                                        label="Cost per application:">
                                    <b-form-input type="number" size="sm" v-model="formData.cost_model.application_cost"
                                                  placeholder="Cost per application"></b-form-input>
                                    <error-display ident="cost_model.application_cost" v-model="errors"></error-display>
                                </b-form-group>
                            </div>
                            <div class="col-6">
                                <b-form-group
                                        label-size="sm"
                                        label="Cost per doc check:">
                                    <b-form-input type="number" size="sm" v-model="formData.cost_model.doc_check_cost"
                                                  placeholder="Cost per doc check"></b-form-input>
                                    <error-display ident="cost_model.doc_check_cost" v-model="errors"></error-display>
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-form-group
                                        label-size="sm"
                                        label="Billing frequency:">
                                    <b-select size="sm" v-model="formData.cost_model.frequency"
                                              :options="frequencyOptions"
                                              placeholder=""></b-select>
                                    <error-display ident="cost_model.frequency" v-model="errors"></error-display>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <b-form-group
                            v-if="formData.workspace_id == null"
                            label-size="sm"
                            label="Limit for card transactions:">
                        <b-form-input type="number" size="sm" v-model="formData.stripe_limit"
                                      placeholder="Automatically bill when org reaches this limit"></b-form-input>
                        <error-display ident="stripe_limit" v-model="errors"></error-display>
                    </b-form-group>

                </div>
                <hr aria-orientation="horizontal" class="dropdown-divider mt-3 mb-3">
                <h6 class="mb-3">FlightPath</h6>
                <div class="px-2">
                    <div class="row">
                        <div class="col-12">
                            <b-form-group
                                    label-size="sm"
                                    label="Organisation FlightPath:">
                                <div v-if="isLoadingPaths" class="d-flex align-items-center border p-2 rounded">
                                    <b-spinner small variant="primary" class="mr-2"></b-spinner>
                                    Loading FlightPaths
                                </div>
                                <div v-else>
                                    <b-select
                                            size="sm"
                                            v-model="formData.flight_path_id"
                                            :options="paths"
                                            placeholder="Select FlightPath">
                                    </b-select>
                                </div>
                                <error-display v-model="errors" ident="flight_path_id"></error-display>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row" v-if="formData.flight_path_id != null">
                        <div class="col-6">
                            <b-form-group
                                    label-size="sm"
                                    label="Access consultant name:">
                                <b-form-input size="sm" v-model="formData.consultant"
                                              placeholder="Account manager name"></b-form-input>
                                <error-display v-model="errors" ident="consultant"></error-display>
                            </b-form-group>
                        </div>
                        <div class="col-6">
                            <b-form-group
                                    label-size="sm"
                                    label="Access consultant email:">
                                <b-form-input size="sm" v-model="formData.consultant_email"
                                              placeholder="Account manager email"></b-form-input>
                                <error-display v-model="errors" ident="consultant_email"></error-display>
                            </b-form-group>
                        </div>
                        <div class="mx-3 mb-3 mt-3 font-weight-bold alert alert-danger flex-fill"
                             v-if="formData.id == null">
                            <p>Please confirm the following before proceeding:</p>
                            <div class="text-danger">
                                <b-icon-exclamation-circle variant="danger" class="mr-2"></b-icon-exclamation-circle>
                                Correct features enabled for customer
                            </div>
                            <div class="text-danger">
                                <b-icon-exclamation-circle variant="danger" class="mr-2"></b-icon-exclamation-circle>
                                Dev team have been informed if they have a screening integration
                            </div>
                        </div>

                    </div>

                </div>

                <div class="text-right">
                    <b-button @click="saveModel" variant="success">Save</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import {
        baseApi,
        flightpaths,
        organisationDetail,
        organisationSave
    } from "../../modules/api/endpoints";
    import ApiSelectInput from "../form_inputs/ApiSelectInput";
    import MaskedInput from "vue-masked-input/src/MaskedInput";
    import moment from "moment";
    import {containsErrors, isset} from "../../modules/helpers/helpers";
    import vSelect from "vue-select"

    export default {
        name: "AdditionalDocumentEdit",
        components: {MaskedInput, ApiSelectInput, ErrorDisplay, "v-select": vSelect},
        props: {
            id: null,
        },
        data: function () {
            return {
                isLoadingPaths: false,
                errorLoading: false,
                paths: [],
                studentOptions: [
                    {value: 'always', text: 'Always ask'},
                    {value: 'never', text: 'Never ask'},
                    {value: 'by_nationality', text: "Don't ask candidates who are British, from Republic of Ireland, Channel Islands, or Isle of Man"},
                ],
                frequencyOptions: [
                    {value: 'monthly', text: 'Monthly'},
                    {value: 'quarterly', text: 'Quarterly'},
                ],
                docCheckOptions: [
                    {value: 'on_upload', text: 'Automatically on upload'},
                    {value: 'on_approve', text: 'Automatically on approval'},
                    {value: 'on_request', text: 'All users can request'},
                    {value: 'on_request_supervisor', text: 'Supervisors can request'},
                    {value: 'off', text: 'Off'},
                ],
                studentStatus: [
                    {value: 'both', text: 'Both'},
                    {value: 'students', text: 'Students only'},
                    {value: 'non_students', text: 'Non students'},
                ],
                isLoading: false,
                formData: {
                    cost_model : {
                        application_cost: null,
                        doc_check_cost: null,
                        frequency: null,
                    },
                    expires_at : moment().add(10,'years').format("DD/MM/YYYY"),
                    ask_student_question : 'by_nationality',
                    stripe_limit : "50.00",
                    student_message : "This candidate is a student and appropriate steps should be taken they are only permitted to work within government guidelines.",
                    student_question : "Does the applicant hold a student visa?"
                },
                errors: {},
                apiEndpoint: baseApi() + 'nationality-groups/dropdown'
            }
        },
        created() {
            this.loadPaths();
            if (this.id != null) {
                this.loadModel();
            } else {
                this.formData.consultant = this.$store.state.user.name;
                this.formData.consultant_email = this.$store.state.user.email;
                this.formData.doc_check_mode = 'off'
            }
        },
        methods: {
            loadPaths() {
                this.isLoadingPaths = true;
                getResource(flightpaths).then((resp) => {
                    this.paths = resp.data.success
                    this.paths.push({'value' : null, 'text' : 'Don\'t use flight path for this organisation'})
                }).catch((ex) => {
                    this.$root.$children[0].handleApiError(ex, this.loadPaths);
                }).finally(() => {
                    this.isLoadingPaths = false;
                });
            },
            loadModel() {
                this.isLoading = true;
                getResource(organisationDetail(this.id)).then((resp) => {
                    var data = resp.data.success.data;

                    if (data.ips !== undefined) {
                        var ips = [];
                        _.each(data.ips, function (obj) {
                            if (obj.ip_address !== undefined) ips.push(obj.ip_address);
                        });
                        data.ips = ips;
                    }
                    if (data.expires_at !== undefined && data.expires_at != null) {
                        data.expires_at = moment(data.expires_at).format("DD/MM/YYYY");
                    }
                    if (data.cost_model == null) {
                        data.cost_model = {
                            application_cost: null,
                            doc_check_cost: null,
                            frequency: null,
                        };
                    } else {
                        data.pricing_override = 1;
                    }

                    this.formData = data;


                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(organisationSave, this.formData).then((resp) => {
                    this.isLoading = false;
                    this.$router.push({'name': 'organisations', params: {data_updated: true}}).catch(error => {});
                }).catch((err) => {
                    console.log(err);
                    if (containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            },
            ipValidator(tag) {
                return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(tag)
            },
            domainValidator(tag) {
                return /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/.test(tag);
            }
        }
    }
</script>

<style scoped>

</style>
